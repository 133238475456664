const routes = {
    // Pages
    ROOT: '/',
    DOCS: '/docs',
    CONTACT_US: '/contact-us',
    PRODUCT: '/product',
    ABOUT_US: '/about-us',
    POLICY: '/policy',

    // ScrollLink
    API_KEY: 'api-key',
    ACTIVATE: 'activate',
    GET_COLLATERAL: 'get-collateral',
    DUE_DATE: 'due-date',
    RELEASE: 'release',
    RELEASE_COLLATERAL: 'release-collateral',
    GET_ALL: 'get-all',
    GET_BY_TAG: 'get-by-tag',
    GET_OFFLINE_UNLOCK: 'get-offline-unlock',
    UPDATE_PRODUCT_ITEM_ID: 'get-by-product-id',
    HOW_IT_WORKS: 'how-it-works',
    DOWNLOAD_COLLECTION: 'download-collection',
    SEND_MESSAGE: 'send-message',
    STOP_BLINK_REMINDER: 'Stop-Blink-Reminder',
    START_BLINK_REMINDER: 'Start-Blink-Reminder',

    AD_DISPLAY: 'product',

    AD: 'ad',
    FULL: 'full',
    PARTIAL: 'partial',

    PRIVACY_POLICY: 'https://www.globetek.com/privacy/policy',
    TERMS_OF_SERVICE: 'https://www.globetek.com/privacy/lockAPK',
};

export default routes;
