import React from "react";
import Navigation from "../components/navigation/Navigation";

class PrivacyPolicy extends React.Component {

    render() {

        return <>
            <Navigation/>
            <section className="container-all-center">
                <div className="privacy-policy" style={{ maxWidth: "800px", padding: "2rem", overflowY: "auto" }}>
                    <h1>Privacy Policy</h1>
                    <p>Last updated: April 8, 2025</p>

                    <p>At <strong>Globetek</strong>, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website and services.</p>

                    <h2>1. Information We Collect</h2>
                    <p>We may collect the following types of information:</p>
                    <ul>
                        <li>Personal Information: Name, email address, phone number, and other contact details.</li>
                        <li>Usage Data: Information about how you use our website, including IP address, browser type, and pages visited.</li>
                        <li>Cookies and Tracking Technologies: We use cookies to improve user experience and analyze website traffic.</li>
                    </ul>

                    <h2>2. How We Use Your Information</h2>
                    <p>Your information may be used for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain our services.</li>
                        <li>To respond to your inquiries and provide customer support.</li>
                        <li>To improve our website and services.</li>
                        <li>To send marketing and promotional communications, if you have opted in.</li>
                    </ul>

                    <h2>3. Sharing Your Information</h2>
                    <p>We do not sell or rent your personal information. We may share your data with third-party service providers who help us operate our business, only when necessary and under strict confidentiality agreements.</p>

                    <h2>4. Data Security</h2>
                    <p>We implement industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.</p>

                    <h2>5. Your Rights</h2>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access and review the personal information we hold about you.</li>
                        <li>Request correction or deletion of your data.</li>
                        <li>Withdraw consent for data processing where applicable.</li>
                    </ul>

                    <h2>6. Changes to This Policy</h2>
                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>

                    <h2>7. Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy or your data, please reach out to us at privacy@globetek.com.</p>
                </div>
            </section>
        </>
    }
}

export default PrivacyPolicy;